<script setup>
import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";

const {configStore} = stores();
const {activeClient} = storeToRefs(configStore);
</script>

<template>
    <img :src="`clients/${activeClient}/logo/logo.png`" alt="logo">
</template>
